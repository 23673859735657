<template>
    <div>
        <div class="text-xs-right">
            <v-btn class="white--text" small round color="teal" :to="{name: 'njangi_groups.new_group'}" >
                <v-icon left class="fas fa-plus " > </v-icon>
                <span> Join New Group</span>
            </v-btn>
        </div>
        <div v-if="groups.length === 0">
            <h1>No of {{$route.params.category}} njangi group exists</h1>
        </div>
        <v-layout row wrap class="">
            <v-flex xs12 sm6 v-if="group_category == 'Active'" v-for="item in groups" :key="item.id" class="pa-1">
                <v-hover >
                    <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 12 : 2}`" :to="'/njangi/groups/detail/'+item.njangi_group_id" style="border-radius: 10px; cursor: pointer; ">
                        <v-layout row wrap class="">
                            <v-flex xs4 class="pa-1">
                                <v-img
                                    :src="item.get_image"
                                    height="120px"
                                > </v-img>
                                <v-divider></v-divider>
                            </v-flex>
                            <v-flex xs8>
                                <v-card-text class="px-3 py-2 " style="font-size: 12px; cursor: pointer">
                                    <h3 :class="titleColor">{{item.njangi_group_name}}</h3>
                                    <span><b>Cycle:</b> {{item.collecting_cycle}}</span>
                                    <v-spacer></v-spacer>
                                    <span><b>Contribution:</b> {{ item.contribution_amount }} F CFA</span>
                                    <v-spacer></v-spacer>
                                    <span><b>Members:</b> {{ item.get_no_members }} </span>
                                    <v-spacer></v-spacer>
                                    <span><b>Collector:</b> {{ item.get_current_collector }}</span>
                                </v-card-text>
                            </v-flex>
                        </v-layout>
                    </v-card>
                </v-hover>
            </v-flex>
            <v-flex xs12 sm6 v-if="group_category == 'Applied'" v-for="item in groups" :key="item.id" class="pa-1">
                <v-hover>
                    <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 12 : 2}`" style="border-radius: 10px; cursor: pointer; ">
                        <v-card-text class="pt-2" style="font-size: smaller">
                            <v-layout row wrap>
                                <v-flex xs6 text-xs-left>
                                    <span class="dark--text font-weight-bold">Type:</span> <span> {{item.njangi_contrib_interval}}</span>
                                </v-flex>
                                <v-flex xs6 text-xs-right>
                                    <span class="dark--text font-weight-bold ">Amount:</span> <span> {{item.prefered_contrib_amount}} F CFA</span>
                                </v-flex>

                                <v-flex xs4 text-xs-left v-if="item.status == 'Pending'">
                                    <span class="dark--text font-weight-bold">Status:</span> <span class="primary--text"> {{item.status}}</span>
                                </v-flex>
                                <v-flex xs4 text-xs-left v-if="item.status == 'Validated'">
                                    <span class="dark--text font-weight-bold">Status:</span><span class="teal--text"> {{item.status}}</span>
                                </v-flex>
                                <v-flex xs4 text-xs-left v-if="item.status == 'Cancelled'">
                                    <span class="dark--text font-weight-bold">Status:</span><span class="red--text"> {{item.status}}</span>
                                </v-flex>

                                <v-flex xs8 text-xs-right>
                                    <span class="dark--text font-weight-bold ">Date:</span><span> {{item.get_date_str}}</span>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                groups: [],
                group_category: '',
            }
        },

        methods:{
            async getGroups(){
                this.$store.commit('setIsLoading', true)

                const group_category = this.$route.params.category  // get campaign category from url and send request to server
                await axios
                    .get('/api/v1/njangi/get/user/groups/'+group_category+'/')
                    .then(response => {
                        this.group_category = group_category
                        this.groups = response.data  // get the data and fill into groups
                        console.log(this.groups)
                        if (group_category === 'All'){
                            this.titleColor = 'grey--text'
                        }
                        else if (group_category === 'Active'){
                            this.titleColor = 'primary--text'
                        }
                        else if (group_category === 'Applied'){
                            this.titleColor = 'red--text'
                        }
                    })
                    .catch(error => {
                        if (error.response){
                            console.log(error.response)
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
        },
        
        watch: {
            '$route': function(to, from) {
                this.getGroups()
            }
        },

        mounted(){
            // executes these after the page has been mounted
            this.getGroups();
            const group_category = this.$route.params.category  // get group category from url and send request to server
            document.title = "PettyCash | "+group_category+" user's groups"
        },
    }
</script>
